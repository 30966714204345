<template>
  <div>
    <div class="container-fluid">
      <PageTitle />

      <b-tabs>
        <b-tab title="Page Settings">
          <div v-if="isList" class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3">
                  <h5 class="card-title">{{ pageTitle }} List</h5>
                </div>
                <div class="col-md-9">
                  <div class="row justify-content-end">
                    <div class="col-12 col-sm-4 mb-2 mb-sm-0">
                      <VSelect
                        v-model="filterStatus"
                        placeholder="-- All Status --"
                        :options="mrStatus"
                        :reduce="(v) => v.status"
                        label="status_description"
                      >
                      </VSelect>
                    </div>
                    <div class="col-12 col-sm-4 mb-2 mb-sm-0">
                      <VSelect
                        v-model="filterFeatured"
                        placeholder="-- All Featured Status --"
                        :options="mrFeatured"
                        :reduce="(v) => v.status"
                        label="status_description"
                      >
                      </VSelect>
                    </div>
                    <div class="col-12 col-sm-2 col-lg-auto">
                      <b-button @click="doReset" class="btn btn-info btn-block"
                        >Reset</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row mt-4">
                <b-col md="12" class="text-center" v-if="data.data===false">
                  <Loader class="mt10 mb10" />
                </b-col>
                <b-col md="3" class="mb-4" v-for="(v, k) in data.data" :key="k">
                  <article class="card_list">
                    <div class="wrap_thumb_list">
                      <bo-card-img
                        :src="uploader(v.aiak_fallback_img)"
                      >
                        <template #status>
                          <LabelStatus :status="v[statusKey]" />
                          <LabelStatus class="mx-1" type="featured" :status="v.aiak_is_featured" />
                        </template>
                        <template #buttons>
                          <b-button
                            v-if="moduleRole('Edit')"
                            variant="secondary"
                            size="sm"
                            pill
                            @click.prevent="
                              $router
                                .push({
                                  name: 'GalleryKlab',
                                  params: { pageSlug: v.aiak_id },
                                })
                                .catch(() => {})
                            "
                            v-b-tooltip.hover="'Edit'"
                          >
                            <i class="fas fa-pencil-alt" />
                          </b-button>
                          <b-button
                            variant="warning"
                            size="sm"
                            pill
                            @click="doChangeStatus(k, v)"
                            v-b-tooltip.hover="'Delete'"
                            class="mx-1"
                          >
                            <i class="fas fa-cog" />
                          </b-button>
                          <b-button
                            variant="danger"
                            size="sm"
                            pill
                            @click="doDelete(k, v)"
                            v-b-tooltip.hover="'Delete'"
                          >
                            <i class="fas fa-trash-alt" />
                          </b-button>
                        </template>
                      </bo-card-img>
                    </div>
                  </article>
                </b-col>
                <b-col v-if="notFound"><span colspan="99" class="text-center"><h4>{{notFound}}</h4></span></b-col>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                  <div
                    class="pagination-flat float-right"
                    v-if="data.data !== false && data.data.length"
                  >
                    <Pagination
                      :data="data"
                      :limit="2"
                      @pagination-change-page="doPaginate"
                    >
                      <span slot="prev-nav">Previous</span>
                      <span slot="next-nav">Next</span>
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="card">
            <div class="card-body">
              <h5 class="card-title mb-3">
                {{ isAdd ? "Add" : "Edit" }} {{ pageTitle }}
              </h5>

              <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
                <form @submit.prevent="handleSubmit(doSubmitCRUD)">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">
                              Status
                              <span class="text-danger mr5">*</span>
                            </label>
                            <div>
                              <InputRadio
                                v-model="row[statusKey]"
                                name="status"
                                option="Y"
                              />
                              <InputRadio
                                v-model="row[statusKey]"
                                name="status"
                                option="N"
                              />
                            </div>
                            <VValidate
                              name="Status"
                              v-model="row[statusKey]"
                              :rules="mrValidation[statusKey]"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">
                              Is Featured?
                              <span class="text-danger mr5">*</span>
                            </label>
                            <div>
                              <InputRadio
                                v-model="row.aiak_is_featured"
                                name="is_featured"
                                option="Y"
                                label="Yes"
                              />
                              <InputRadio
                                v-model="row.aiak_is_featured"
                                name="is_featured"
                                option="N"
                                label="No"
                              />
                            </div>
                            <VValidate
                              name="Status"
                              v-model="row.aiak_is_featured"
                              :rules="mrValidation.aiak_is_featured"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="row">
                        <b-col md="6">
                          <b-form-group>
                            <label>
                              Image <span class="text-danger mr5">*</span>
                            </label>
                            <Uploader
                              :readonly="!moduleRole('Edit')"
                              v-model="row.aiak_fallback_img"
                              :param="{ thumbnail: true }"
                              :squarePreview="true"
                              type="gallery"
                              label="Image"
                              @data="(v) => (row.aiak_img = v)"
                            />
                            <VValidate
                              name="Image"
                              v-model="row.aiak_img"
                              :rules="mrValidation.aiak_img"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group>
                            <label>
                              Image Small <span class="text-danger mr5">*</span>
                            </label>
                            <Uploader
                              :readonly="!moduleRole('Edit')"
                              v-model="row.aiak_fallback_small_img"
                              :param="{ thumbnail: true }"
                              :squarePreview="true"
                              type="gallery_small"
                              label="Image"
                              @data="(v) => (row.aiak_small_img = v)"
                            />
                            <VValidate
                              name="Image Small"
                              v-model="row.aiak_small_img"
                              :rules="mrValidation.aiak_small_img"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <BoField
                            label="Alt Image"
                            placeholder="Alt Image"
                            v-model="row.aiak_alt_img"
                            :rules="mrValidation.aiak_alt_img"
                          />
                        </b-col>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-12 text-right">
                      <router-link
                        :to="{ name: $route.name }"
                        class="btn btn-rounded btn-light mr-2"
                        >Cancel</router-link
                      >
                      <button type="submit" class="btn btn-rounded btn-info">
                        {{ isAdd ? "Submit" : "Update" }}
                      </button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </b-tab>
      </b-tabs>

    </div>
  </div>
</template>

<script>
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";
import BoCardImg from "@/components/BoCardImg.vue";

import ClassicEditor from "@libs/ckeditor5-custom-build/build/ckeditor";
import { extend } from "vee-validate";

export default {
  extends: GlobalVue,
  components: {
    PageTitle,
    BoCardImg,
  },
  data() {
    return {
      idKey: "aiak_id",
      statusKey: "aiak_is_active",
      editor: ClassicEditor,
      mrFeatured: [],
      editorConfig: {
        fontColor: {
          colors: [
            {
              color: "hsl(221, 99%, 43%)",
              label: "Blue",
            },
            {
              color: "hsl(0, 83%, 47%)",
              label: "Red",
            },
            {
              color: "hsl(93, 69%, 49%)",
              label: "Green",
            },
            {
              color: "hsl(322, 100%, 62%)",
              label: "Pink",
            },
            {
              color: "hsl(0, 0%, 0%)",
              label: "Black",
            },
            {
              color: "hsl(0, 0%, 30%)",
              label: "Dim grey",
            },
            {
              color: "hsl(0, 0%, 60%)",
              label: "Grey",
            },
            {
              color: "hsl(0, 0%, 90%)",
              label: "Light grey",
            },
            {
              color: "hsl(0, 0%, 100%)",
              label: "White",
              hasBorder: true,
            },
          ],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        ui: {
          viewportOffset: {
            top: 65,
          },
        },
        toolbar: {
          items: ["heading", "|", "bold", "fontColor", "link"],
        },
      },
    };
  },
  computed: {
    filterStatus: {
      get() {
        return this.filter.status;
      },
      set(newValue) {
        this.filter = {
          ...this.filter,
          status: newValue,
        };
        this.doFilter();
      },
    },
    filterFeatured: {
      get() {
        return this.filter.featured;
      },
      set(newValue) {
        this.filter = {
          ...this.filter,
          featured: newValue,
        };
        this.doFilter();
      },
    },
  },
  methods: {
  },
  mounted() {
    extend("editor", {
      message: "The {_field_} field must be at least 10 characters",
      validate: (value) => {
        try {
          return this.sanitize(value).length >= 10;
        } catch (error) {
          console.log(error);
        }
      },
    });
  },
  watch: {
    $route: {
      handler() {
        this.apiGet();
      },
      immediate: true,
    },
  },
};
</script>

<style>
.add_hero {
  min-height: 130px;
  border: 2px dotted rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>